/* eslint-disable */
 const multiSelect= `<problem>
  <choiceresponse>
    <checkboxgroup>
      <choice correct="true"></choice>
      <choice correct="false"></choice>
      <choice correct="false"></choice>
    </checkboxgroup>
  </choiceresponse>
</problem>`

export default multiSelect;